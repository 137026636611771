import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../../components/news-strip"

class NewsPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Tetley's win Mid-Yorkshire NHS Trust contract" description="Tetley's Coaches wins the Mid-Yorkshire NHS Trust contract to provide staff and patient transport between Pontefract, Pinderfields and Dewsbury hospitals following a comprehensive tender process." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Tetley's win Mid-Yorkshire NHS Trust contract</h1>
              <p className="news-article-date">July 2014</p>
            </div>

            <div className="news-article-body">
              <p>
                Tetley's Coaches wins the Mid-Yorkshire NHS Trust contract to provide staff and patient transport between Pontefract, Pinderfields and Dewsbury hospitals following a comprehensive tender process.
              </p>
              <p>Three DDA (Disability Discrimination Act) compliant, low floor Optare buses have been purchased specifically for the year round, 365 day operation.</p>
              <p>Read more on the <a href="http://www.dewsburyreporter.co.uk/news/local/free-bus-service-to-link-dewsbury-wakefield-and-pontefract-hospitals-1-6733372">Dewsbury Reporter</a>.</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
